import React from 'react'
import { graphql } from 'gatsby'
import Navbar from '../sections/Navbar/Navbar'
import Footer from '../sections/Footer'
import { PrismicRichText } from "@prismicio/react";
import "../styles/LegalDocs.scss";
import LanguageSelectorDropdown from '../components/LanguageSelectorDropdown';

const PrivacyPolicy = ({ data }: any) => {
  const info = data.prismicLegalDoc.dataRaw
  return (
    <div>
      <Navbar />
      <div className="LegalDocs">
        <div className='LegalDocs-title'>
          <PrismicRichText field={info.title} />
        </div>
        <div className="LegalDocs-top">
          <PrismicRichText field={info.text_body} />
        </div>
        <div className='LegalDocs-second-title'>
          <PrismicRichText field={info.second_title} />
        </div>
        <div>
          <PrismicRichText field={info.global_text}
            components={{
              paragraph: ({ children }) => <p className="py-3">{children}</p>,
            }}
          />
        </div>
        <img className="Logo_coachello_pp" alt="logo_coachello" src={data.prismicLegalDoc.data.logo_coachello.url} />
      </div>
      <div className="fixed bottom-6 z-50 right-6">
        <LanguageSelectorDropdown />
      </div>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy;

export const query = graphql`
query PrivacyPolicy  {
  prismicLegalDoc(uid: {eq: "privacy-policy-english"}, lang: {eq: "en-us"}) {
    uid
    dataRaw
    lang
    url
    data {
      logo_coachello {
        url
      }
    }
  }
}
`;